import React from 'react';
import commaNumber from 'comma-number';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faInfoCircle, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityPlannerMonthlyPromoters.scss';

import Loader from '../../Loader/Loader';
import Select from '../../General/Select';
import Tooltip from '../../General/Tooltip';
import CostReasoningOverlay from '../Elements/CostReasoningOverlay';
import CustomTooltip from '../../General/CustomTooltip';

import { getPlanUserFromUser, isUserInPlan } from '../../../Helpers/planning_helpers';
import { opportunityExpectsLinks } from '../../../Helpers/opportunity_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeatureOrAdmin } from '../../../Helpers/subscription_helpers';
import { getUserTierDisplay } from '../../../Helpers/tier_helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';

const OpportunityPlannerMonthlyPromoters = props => {
  const {
    user,
    opportunity,
    activePlan,
    changeSort,
    sortBy,
    sortByMonth,
    generosityMultiple,
    preferredSocialPlatforms,
    isFetchingFirstPage,
    results,
    isFetchingNextPage
  } = props;

  // UI State Management
  const [modifyingUserIds, setModifyingUserIds] = React.useState([]);
  const startModifyingUser = user => setModifyingUserIds([...modifyingUserIds, user.id]);
  const stopModifyingUser = user => setModifyingUserIds(modifyingUserIds.filter(id => id !== user.id));
  const isUserBeingModified = user => modifyingUserIds.includes(user.id);

  // Sort Values
  const endMonth = moment()
    .subtract(1, 'month')
    .format('YYYY-MM');
  const months = _.reverse(
    _.range(6).map(i =>
      moment(endMonth)
        .subtract(i, 'months')
        .format('YYYY-MM')
    )
  );
  const findDataForUserMonth = (user, month) => user.monthly_data.find(data => data.month === month);

  // Display Values
  const [displayMetricVariable, setDisplayMetricVariable] = React.useState(opportunityExpectsLinks(opportunity) ? 'volume' : 'emv');
  const displayMetricOptions = [
    {
      variable: 'volume',
      value: 'volume',
      maxRoiMetric: 'max_potential_roi_volume',
      totalMetric: 'total_volume',
      label: 'Order Volume'
    },
    {
      variable: 'clicks',
      value: 'clicks',
      maxRoiMetric: 'max_potential_roi_clicks',
      showInverseROI: true,
      totalMetric: 'total_clicks',
      label: '# Clicks'
    },
    {
      variable: 'emv',
      value: 'emv',
      maxRoiMetric: 'max_potential_roi_emv',
      totalMetric: 'total_emv',
      label: 'Media Value (EMV)'
    }
  ];
  const displayMetric = displayMetricOptions.find(option => option.variable === displayMetricVariable);
  const changeDisplayMetricValue = newValue => {
    const newDisplayMetric = displayMetricOptions.find(option => option.variable === newValue);
    setDisplayMetricVariable(newValue);
    changeSort(sortByMonth ? newDisplayMetric.variable : newDisplayMetric.maxRoiMetric, sortByMonth);
  };

  // Generosity Multiples
  let generosityMultipleOptions = [
    {
      value: 1.5,
      label: 'Very Generous',
      sublabel: 'This setting offers prices that are 1.5x the recommended offer. This will drastically increase acceptance rates.'
    },
    {
      value: 1.25,
      label: 'Generous',
      sublabel: 'This setting offers prices that are 1.25x the recommended offer. This will likely increase acceptance rates.'
    },
    { value: 1, label: 'Normal', sublabel: 'This is the recommended offer per the ShopMy algorithm.' },
    {
      value: 0.8,
      label: 'Aggressive',
      sublabel: 'This setting offers prices that are 0.8x recommended offer. This will likely reduce acceptance rates'
    },
    {
      value: 0.6,
      label: 'Very Aggressive',
      sublabel: 'This setting offers prices that are 0.6x recommended offer. This will likely reduce acceptance rates'
    }
  ];

  // Add custom
  const isCurrentlyOnStandardGenerosity = !!generosityMultipleOptions.find(option => option.value === props.generosityMultiple);
  generosityMultipleOptions.push({
    value: isCurrentlyOnStandardGenerosity ? null : props.generosityMultiple,
    label: isCurrentlyOnStandardGenerosity ? 'Custom' : `${generosityMultiple.toFixed(1)}x`,
    sublabel: 'Configure the multiple on generosity that you would like to use.'
  });

  const changeGenerosityMultiple = newValue => {
    if (!newValue) {
      const value = window.prompt('Enter the custom generosity multiple you would like to use.');
      if (!value) return;
      newValue = parseFloat(value);
    }

    props.setGenerosityMultiple(newValue);
    props.setPage(0);
    props.setHasAllResults(false);
  };

  // Social Platforms
  const preferredSocialPlatformOptions = [
    { value: 'instagram,tiktok,youtube', label: 'No Preference', sublabel: 'This will establish pricing based on all of their social profiles.' },
    { value: 'instagram', label: 'Instagram', sublabel: 'This will ignore non-Instagram social data when setting prices.' },
    { value: 'tiktok', label: 'TikTok', sublabel: 'This will ignore non-TikTok social data when setting prices.' },
    { value: 'youtube', label: 'YouTube', sublabel: 'This will ignore non-YouTube social data when setting prices.' },
    { value: 'instagram,tiktok', label: 'Instagram & TikTok', sublabel: 'This will only consider Instagram and TikTok data when setting prices.' }
  ];

  // Ensure if they have a custom configuration, we show that as an option
  if (opportunity.mentionPlatforms && !preferredSocialPlatformOptions.find(option => option.value === opportunity.mentionPlatforms)) {
    const display = opportunity.mentionPlatforms
      .split(',')
      .map(platform => platform.charAt(0).toUpperCase() + platform.slice(1))
      .join(' & ');
    preferredSocialPlatformOptions.push({
      value: opportunity.mentionPlatforms,
      label: `${display}`,
      sublabel: `These are custom based on your opportunity expectation settings.`
    });
  }

  const changePreferredSocialPlatforms = newValue => {
    props.setPreferredSocialPlatforms(newValue);
    props.setPage(0);
    props.setHasAllResults(false);
  };

  // Grid / Columns
  const prettyPrice = num => (num ? '$' + commaNumber(num.toFixed(0)) : '-');
  const prettyNumber = num => (num ? commaNumber(num.toFixed(0)) : '-');
  const getValueForUserGivenDisplayMetric = (user, month) => {
    const data = findDataForUserMonth(user, month);
    if (displayMetricVariable === 'volume') return data?.volume || 0;
    if (displayMetricVariable === 'clicks') return data?.clicks || 0;
    if (displayMetricVariable === 'emv') return data?.emv || 0;
    return null;
  };
  const columns = [
    {
      display: 'Your Promoter',
      getValue: user => user.name,
      getDisplay: user => {
        const { name, image, tier, opportunity_acceptance_rate } = user;
        const openProfile = () => props.openArtistModal(user);

        let metadata = [];
        if (tier) metadata.push(getUserTierDisplay(tier));

        if (!_.isNil(opportunity_acceptance_rate)) {
          metadata.push(opportunity_acceptance_rate ? `${opportunity_acceptance_rate.toFixed(0)}% Acceptance` : `Never Accepted`);
        } else {
          metadata.push('Never Received');
        }

        return (
          <div onClick={openProfile} className='creator-cell'>
            <img src={image} alt={name} />
            <div className='data'>
              <div className='name'>{name}</div>
              {!!metadata.length && <div className='metadata'>{metadata.join(' • ')}</div>}
            </div>
          </div>
        );
      }
    },
    ...(displayMetric.variable === 'volume'
      ? [
          {
            display: 'All Brands',
            displayTooltip: 'This is their monthly order volume driven to all brands.',
            requiresModule: 'ADVANCED_TALENT_ANALYTICS',
            sortByValue: 'peakMonthlyVolume',
            sortByMonthValue: null,
            getValue: user => user.peakMonthlyVolume,
            additionalClasses: { small: true },
            getDisplay: user => {
              return user.peakMonthlyVolume ? (
                <div className='cell'>
                  <div className='display small'>
                    ${getPrettyNumber(user.peakMonthlyVolume)}
                    <span className='per-month'>/mo</span>
                  </div>
                </div>
              ) : (
                '-'
              );
            }
          }
        ]
      : []),
    {
      display: 'Last 6mo',
      sortByValue: displayMetric.totalMetric,
      sortByMonthValue: null,
      getValue: user => user[displayMetric.totalMetric],
      additionalClasses: { small: true },
      getDisplay: user => {
        const value = user[displayMetric.totalMetric];
        if (['volume', 'emv'].includes(displayMetricVariable)) return prettyPrice(value);
        return prettyNumber(value);
      }
    },
    ...months.map(month => ({
      display: moment(month).format('MMM'),
      sortByValue: displayMetric.variable,
      sortByMonthValue: month,
      getValue: user => getValueForUserGivenDisplayMetric(user, month),
      getDisplay: user => {
        const value = getValueForUserGivenDisplayMetric(user, month);
        if (['volume', 'emv'].includes(displayMetricVariable)) return prettyPrice(value);
        return prettyNumber(value);
      },
      useShading: true
    })),
    {
      display: 'Fee',
      sortByValue: 'recommended_cost',
      sortByMonthValue: null,
      getValue: user => user.recommended_cost,
      getDisplay: user => {
        const planUser = getPlanUserFromUser(activePlan, user);
        const cost = planUser?.recommendedFixedFee || user.recommended_cost;
        const selectCost = () => props.specifyCostForUser(user);
        return (
          <div className='cell icon-cell'>
            <div className='display'>
              {window.__ADMIN_CONTROL_MODE__ && user.recommended_cost_reasoning && (
                <div className='reasoning'>
                  <CustomTooltip tooltipEl={<CostReasoningOverlay reasoning={user.recommended_cost_reasoning} />}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </CustomTooltip>
                </div>
              )}
              <div onClick={selectCost} className='cost'>
                {prettyPrice(cost)}
              </div>
              <div onClick={selectCost} className={cn('icon', { active: !!planUser })}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </div>
            </div>
          </div>
        );
      }
    },
    ...(displayMetric.variable === 'volume'
      ? [
          {
            display: 'Proj ROI',
            displayTooltip:
              'This value this shows the expected ROI at the recommended fee. This is calculated by looking at their recent performance on opportunities similar to this one. If a creator does not have a value it means we do not have enough data to calculate this value.',
            sortByValue: 'roi_per_link',
            sortByMonthValue: null,
            getValue: user => user.opportunitiesROIPerLinkLast90,
            additionalClasses: { small: true },
            getDisplay: user => {
              if (!user.roi_per_link) return '-';

              // If the user overrode the recommended cost, we need to adjust the ROI accordingly
              let manualAdjustment = 1;
              const planUser = getPlanUserFromUser(activePlan, user);
              if (planUser && planUser.recommendedFixedFee !== user.recommended_cost) {
                manualAdjustment = planUser.recommendedFixedFee / user.recommended_cost;
              }

              return `${(user.roi_per_link / manualAdjustment).toFixed(1)}x`;
            }
          }
        ]
      : []),
    {
      display: 'Actions',
      getValue: () => !!getPlanUserFromUser(activePlan, user),
      getDisplay: user => {
        const planUser = getPlanUserFromUser(activePlan, user);
        const isInPlan = !!planUser;
        const isModifying = isUserBeingModified(user);

        const add = async () => {
          startModifyingUser(user);
          await props.addCreatorToPlan(user);
          stopModifyingUser(user);
        };
        const remove = async () => {
          await props.removeCreatorFromPlan(planUser);
        };

        return (
          <div key={user.id} className='cell'>
            <div className='actions'>
              <div
                onClick={isModifying ? () => {} : isInPlan ? remove : add}
                className={cn('action', {
                  remove: isInPlan,
                  add: !isInPlan,
                  loading: isModifying
                })}
              >
                {isInPlan ? 'Remove' : 'Add'}
                {isModifying && <Loader size={32} />}
              </div>
            </div>
          </div>
        );
      }
    }
  ];

  // Loading
  const getLoadingRow = idx => (
    <div key={idx} className={cn('row loading', `grid-${columns.length}`)}>
      {columns.map((c, idx) => {
        return (
          <div key={idx} className='cell'>
            <Loader size={40} />
          </div>
        );
      })}
    </div>
  );

  // Coloring
  const allValues = _.flatten(results.map(user => _.values(user.monthly_data).map(month => month[displayMetric])));
  const avgToConsider = _.mean(_.orderBy(allValues, [], ['desc']).slice(10, 30));
  const getBackgroundOpacityForMonthValue = (user, column) => {
    const value = column.getValue(user);
    const values = _.values(user.monthly_data).map(month => month[displayMetric.variable]);
    const max = Math.max(...values);
    const rowImpact = _.min([max / avgToConsider, 1]);
    return max ? (rowImpact * value) / max : 0;
  };

  // Advanced Selects
  const advancedSelects = [
    {
      label: 'Display Metric',
      tooltip: 'Select the metric you would like to use to sort and display the data.',
      options: displayMetricOptions,
      value: displayMetricVariable,
      onChange: changeDisplayMetricValue
    },
    {
      label: 'Offer Generosity',
      tooltip: 'Apply an adjustment to our fee recommendations to prioritize higher acceptance rates or higher projected ROI.',
      options: generosityMultipleOptions,
      value: generosityMultiple,
      onChange: changeGenerosityMultiple
    },
    {
      label: 'Social Platforms Considered',
      tooltip: 'Specify which social platforms you want us to analyze when calculating the recommended fees.',
      options: preferredSocialPlatformOptions,
      value: preferredSocialPlatforms,
      onChange: changePreferredSocialPlatforms
    }
  ];

  return (
    <div className='opportunity-planner-monthly-promoters'>
      <div className='additional-controls'>
        <div className='main'>
          {advancedSelects.map(select => {
            const { label, options, tooltip, value, onChange } = select;
            return (
              <div key={label} className='select-container'>
                <div className='metric-select-label-container'>
                  <div className='metric-select-label'>{label}</div>
                  {tooltip && (
                    <Tooltip message={tooltip}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  )}
                </div>
                <Select isDark className='metric-select' isSearchable={false} options={options} value={value} onChangeValue={onChange} />
              </div>
            );
          })}
        </div>
        <div className='secondary'>
          <div className='disclaimer'>
            <FontAwesomeIcon icon={faInfoCircle} />
            The price recommendations are based on the current opportunity expectations:{' '}
            {[
              opportunity.mentionsExpected ? `${opportunity.mentionsExpected} mention${opportunity.mentionsExpected > 1 ? 's' : ''}` : '',
              opportunity.linksExpected ? `${opportunity.linksExpected} link${opportunity.linksExpected > 1 ? 's' : ''}` : '',
              opportunity.mentionDaysExpected
                ? `${opportunity.mentionDaysExpected} mention day${opportunity.mentionDaysExpected > 1 ? 's' : ''}`
                : '',
              opportunity.linkingDaysExpected ? `${opportunity.linkingDaysExpected} linking day${opportunity.linkingDaysExpected > 1 ? 's' : ''}` : ''
            ]
              .filter(a => a)
              .join(', ')}
          </div>
        </div>
      </div>
      <div className={cn('results-header', `grid-${columns.length}`)}>
        {columns.map(column => {
          const { display, sortByValue, sortByMonthValue, requiresModule, displayTooltip, isROIColumn } = column;
          const hasSort = !!sortByValue || !!sortByMonthValue;
          const click = () => {
            if (requiresModule && blockOnRequiringSubscription(user, requiresModule)) return;
            hasSort && changeSort(sortByValue, sortByMonthValue);
          };
          const isCurrentSort = sortByMonthValue ? sortByMonth === sortByMonthValue : sortBy === sortByValue;
          return (
            <div key={display} onClick={click} className={cn('header-cell', { clickable: hasSort })}>
              <div
                className={cn('display', {
                  'active-sort': isCurrentSort
                })}
              >
                {display}
                {displayTooltip && (
                  <Tooltip message={displayTooltip}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                )}
                {hasSort && (
                  <FontAwesomeIcon
                    className='sort-icn'
                    icon={
                      props.sortDir === 'asc' && isCurrentSort
                        ? displayMetric.showInverseROI && isROIColumn
                          ? faArrowDown
                          : faArrowUp
                        : displayMetric.showInverseROI && isROIColumn
                        ? faArrowUp
                        : faArrowDown
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={cn('results-container', {
          'fetching-first-page': isFetchingFirstPage,
          'fetching-results': !results.length
        })}
      >
        {isFetchingFirstPage && !results.length
          ? _.range(4).map(getLoadingRow)
          : results.map(user => {
              const isInPlan = isUserInPlan(activePlan, user);
              return (
                <div key={user.id} className={cn('row', `grid-${columns.length}`, { 'in-plan': isInPlan })}>
                  {columns.map((column, idx) => {
                    const { getDisplay, useShading, requiresModule, additionalClasses } = column;
                    const display = getDisplay(user);
                    const backgroundOpacity = useShading ? getBackgroundOpacityForMonthValue(user, column) : 0;
                    const background = 'rgba(17, 131, 90, ' + backgroundOpacity + ')';
                    const isSubscribed = !requiresModule || isSubscribedToFeatureOrAdmin(props.user, requiresModule);
                    return typeof display === 'string' || !isSubscribed ? (
                      <div key={idx} className='cell'>
                        <div
                          style={{
                            background,
                            color:
                              backgroundOpacity < 0.2 ? '#171919' : backgroundOpacity < 0.3 ? '#333' : backgroundOpacity < 0.8 ? 'white' : '#e5e5e5'
                          }}
                          className={cn('display', { 'use-shading': useShading, disabled: !isSubscribed, ...(additionalClasses || {}) })}
                        >
                          {isSubscribed ? display : 'Hidden'}
                        </div>
                      </div>
                    ) : (
                      display
                    );
                  })}
                </div>
              );
            })}
        {isFetchingNextPage && (
          <div className={cn('row loading', `grid-${columns.length}`)}>
            {columns.map(c => {
              return (
                <div className='cell'>
                  <Loader size={40} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

OpportunityPlannerMonthlyPromoters.propTypes = {
  // Data
  results: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  activePlan: PropTypes.object,

  // UI
  changeSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  sortByMonth: PropTypes.string,
  isFetchingFirstPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,

  // Advanced Options
  generosityMultiple: PropTypes.number.isRequired,
  preferredSocialPlatforms: PropTypes.string.isRequired,

  // Actions
  addCreatorToPlan: PropTypes.func.isRequired,
  removeCreatorFromPlan: PropTypes.func.isRequired,
  updateCreatorInPlan: PropTypes.func.isRequired,
  specifyCostForUser: PropTypes.func.isRequired
};

export default OpportunityPlannerMonthlyPromoters;
