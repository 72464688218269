import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import cn from 'classnames';
import './UserTagsPanel.scss';

import { getOnboardingTags } from '../../../../APIClient/tags';
import { addTagsToUser, deleteTagsForUser } from '../../../../Actions/UserActions';

import { getTags } from '../../../../Helpers/user_helpers';
import { cleanTag } from '../../../../Helpers/tag_helpers';

const UserTagsPanel = props => {
  const currentUserTags = getTags(props.user).filter(tag => tag.isOnboarding).map(cleanTag); // prettier-ignore

  /*************************************************** */
  // State
  /*************************************************** */
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTags, setSelectedTags] = useState(currentUserTags);
  const [allTags, setAllTags] = useState([]);

  // Group tags. This is the same as in OnboardingPanelTags.js
  const allTagGroups = { geo: [], general: [], other: [] };
  allTags.forEach(tag => {
    if (tag.type === 'geo') allTagGroups.geo.push(tag);
    else if (tag.type === 'general') allTagGroups.general.push(tag);
    else allTagGroups.other.push(tag);
  });

  /*************************************************** */
  // Data Loading / Request Handling
  /*************************************************** */
  const fetchAllPossibleUserTags = async () => {
    if (isLoading) return window.ALERT.error('Please wait for the current operation to complete.');
    setIsLoading(true);

    try {
      const tagsResponse = await getOnboardingTags();
      setAllTags(tagsResponse);
    } catch (e) {
      window.ALERT.error('Error fetching tags');
    } finally {
      setIsEditing(true);
      setIsLoading(false);
    }
  };

  const doneEditing = async () => {
    setIsEditing(false);
    if (_.isEqual(selectedTags, currentUserTags)) return;

    setIsLoading(true);
    const newTags = selectedTags.filter(tag => !currentUserTags.find(t => t.id === tag.id));
    const deletedTags = currentUserTags.filter(tag => !selectedTags.find(t => t.id === tag.id));
    try {
      await Promise.all([newTags.length && props.addTagsToUser(newTags), deletedTags.length && props.deleteTagsForUser(deletedTags)]);
    } catch (e) {
      window.ALERT.error('Error saving tags. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  /*************************************************** */
  // UI Handling
  /*************************************************** */
  const isTagSelected = tag => !!selectedTags.find(t => t.id === tag.id);
  const toggleTag = tag => setSelectedTags(isTagSelected(tag) ? _.filter(selectedTags, t => t.id !== tag.id) : [...selectedTags, tag]);
  const getTag = tag => {
    const toggle = () => isEditing && toggleTag(tag);
    const selected = isTagSelected(tag);
    return (
      <button key={tag.id} onClick={toggle} className={cn('settings-tag', { selected: selected && isEditing, toggleable: isEditing })}>
        {cleanTag(tag).value}
      </button>
    );
  };

  return (
    <div className='user-tags-panel-outer'>
      <div className='user-tags-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Your Creator Tags</div>
          <div className='settings-section-subtitle'>
            These tags will help brands to find you when they are discovering new creators to work with.
          </div>

          <div className='tags-container'>
            {isEditing ? (
              <div className='tag-sections'>
                <div className='tag-section'>
                  <div className='section-header'>Where are you based?</div>
                  <div className='tags'>{allTagGroups.geo.map(getTag)}</div>
                </div>
                <div className='tag-section'>
                  <div className='section-header'>Are you any of the following?</div>
                  <div className='tags'>{allTagGroups.general.map(getTag)}</div>
                </div>
                <div className='tag-section'>
                  <div className='section-header'>What best describes your content?</div>
                  <div className='tags'>{allTagGroups.other.map(getTag)}</div>
                </div>
              </div>
            ) : selectedTags.length ? (
              <div className='tag-sections'>
                <div className='tag-section'>
                  <div className='tags'>
                    {selectedTags.map(getTag)}
                    <button onClick={fetchAllPossibleUserTags} className='settings-tag selected'>
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <button onClick={fetchAllPossibleUserTags} className='settings-button restricted'>
                Add Tags
              </button>
            )}
          </div>

          {isEditing && (
            <button onClick={doneEditing} className='settings-button restricted'>
              Done
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

UserTagsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  addTagsToUser: PropTypes.func.isRequired,
  deleteTagsForUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  addTagsToUser,
  deleteTagsForUser
})(UserTagsPanel);
