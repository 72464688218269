import React from 'react';
import moment from 'moment';
import commaNumber from 'comma-number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { isAdminControlMode } from './ui_helpers';
import { getPrettyDate } from './formatting';
import { prepopulateIntercomMessage } from './chat_helpers';
import { getBrand, getBrandSubscription, isSMSAffiliatePartnerBrand, getBrandBudgetRemaining } from './user_helpers';

/*
  While accessing the redux store directly is not recommended, we do it here because we want to block code paths
  based on the user's subscription level. Please do not replicate this pattern elsewhere in the codebase unless
  you are sure you need to.
*/
import storeData from '../configureStore';
import { openSubscriptionOfferModal, openUnpaidInvoicesModal } from '../Actions/UIActions';
import { getUnpaidBrandSubscriptionInvoicesPastDue, getUnpaidBrandAffiliateInvoicesPastDue } from '../Helpers/user_helpers';
import { getUIKeyValueGeneral } from '../Helpers/ui_helpers';

export const blockOnRequiringSubscriptionToFeature = (feature, options) =>
  blockOnRequiringSubscription(storeData.store.getState().user, feature, options);
export const blockOnRequiringSubscription = (user, feature, options = {}) => {
  /*
    Use the following logic to block certain code paths:
  
      if (blockOnRequiringSubscription(user, 'FEATURE_HERE')) return;
  */
  const { directIntegrationRequired } = options;
  const { ui } = storeData.store.getState();
  const brand = getBrand(user);

  // Allow Admins to access all features
  if (isAdminControlMode(ui)) return false;

  // Ensure they have paid their invoices
  const subscriptionInvoicesPastDue = getUnpaidBrandSubscriptionInvoicesPastDue(user);
  const affiliateInvoicesPastDue = getUnpaidBrandAffiliateInvoicesPastDue(user);
  const brandBudgetInvoicesPastDue = getUnpaidBrandAffiliateInvoicesPastDue(user);
  const invoicesPastDue = [...subscriptionInvoicesPastDue, ...affiliateInvoicesPastDue, ...brandBudgetInvoicesPastDue];
  const invoicesPastDueThatAreOverDaysOldLimit = invoicesPastDue.filter(
    invoice => moment().diff(moment(invoice.dueOn), 'days') >= (brand?.settings?.daysUntilInvoicePastDueToAlert || 30)
  );

  const dismissedUnpaidInvoicesModalAt = getUIKeyValueGeneral(ui, 'dismissUnpaidInvoicesModalUntil');
  const hasDismissedUnpaidInvoicesModal = dismissedUnpaidInvoicesModalAt && new Date().getTime() < dismissedUnpaidInvoicesModalAt;
  if (invoicesPastDueThatAreOverDaysOldLimit.length && !hasDismissedUnpaidInvoicesModal) {
    storeData.store.dispatch(openUnpaidInvoicesModal({ feature }));
    return true;
  }

  // This feature requires a direct integration with the ShopMy affiliate network
  const hasIntegrationStatus = directIntegrationRequired ? !!isSMSAffiliatePartnerBrand(user) : true;
  if (!hasIntegrationStatus) {
    window.ALERT.warn(
      'You must integrate directly with the ShopMy affiliate network to leverage this feature. Please contact your account representative to configure the integration.'
    );
    return true;
  }

  // If they have an active subscription, let them through
  const hasAccess = isSubscribedToFeature(user, feature);
  if (hasAccess) return false;

  storeData.store.dispatch(openSubscriptionOfferModal({ params: { feature } }));
  return true;
};

export const isSubscribedToFeature = (user, feature) => {
  /*
    Checks whether one of the user's subscription items includes access to the feature.
  */
  const subscription = getBrandSubscription(user);
  const items = subscription?.items || [];
  const itemThatUnlocksFeature = items.find(item => {
    const itemUnlocksFeature =
      item.offer.canAccessEnum?.split(',')?.includes(feature) || item.offer.canAccessDeprecatedEnum?.split(',')?.includes(feature);
    const itemIsActive = !item.isCancelled;
    return itemUnlocksFeature && itemIsActive;
  });
  return !!itemThatUnlocksFeature;
};

export const isSubscribedToFeatureOrAdmin = (user, feature) => {
  return isAdminControlMode(storeData.store.getState().ui) || isSubscribedToFeature(user, feature);
};

export const blockOnRequiringBudget = (user, amount, openBrandBudgetModal) => {
  /*
    Some features require a budget to be set on the brand. Use this function to block code paths that require a budget.

    Example:
      if (blockOnRequiringBudget(user, 100, props.openBrandBudgetModal)) return null

  */

  // Ensure they are a brand
  const brand = getBrand(user);
  if (!brand) return false;

  // Ensure the amount is a positive number
  if (!amount) return false;

  // Ensure they have the budget
  const budgetRemaining = getBrandBudgetRemaining(user);
  const hasBudget = budgetRemaining >= amount;
  if (hasBudget) return false;

  openBrandBudgetModal({ amountRequired: amount });
  return true;
};

export const getInvoiceTableData = user => {
  /*
    Returns table formatting to power the AffiliateInvoices, SubscriptionInvoices and BrandBudgetInvoices instances
  */
  return [
    {
      header: 'Sent On',
      getValue: invoice => getPrettyDate(invoice.sentOn || invoice.createdAt)
    },
    {
      header: 'Due On',
      getValue: invoice => (invoice.dueOn ? getPrettyDate(invoice.dueOn) : '-')
    },
    {
      header: 'Amount',
      getValue: invoice => '$' + commaNumber(invoice.amount)
    },
    {
      header: 'Status',
      getValue: invoice => invoice.payment_status || invoice.status
    },
    {
      header: 'Invoice',
      getValue: invoice => {
        const stripeUrl =
          invoice.stripeHostedUrl ||
          (invoice.stripeInvoiceId ? `https://dashboard.stripe.com/${window.__IS_DEV__ ? 'test/' : ''}invoices/${invoice.stripeInvoiceId}` : null);
        const requestInvoice = () => {
          prepopulateIntercomMessage(`I need a copy of invoice #${invoice.id}`);
        };
        return stripeUrl ? (
          <a href={stripeUrl} target='_blank' rel='noopener noreferrer'>
            View
            <FontAwesomeIcon icon={faExternalLinkAlt} className='external-link-icon' />
          </a>
        ) : (
          <div onClick={requestInvoice}>Request Invoice</div>
        );
      }
    }
  ];
};
