import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './UserTierPanel.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExternalLink, faCircle, faPlusLarge, faMinusLarge } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import { getUserTier } from '../../../../Helpers/user_helpers';
import { getPrettyNumber } from '../../../../Helpers/formatting';
import {
  getUserTiers,
  isUserAtRiskOfTierDemotion,
  getDaysUntilTierDemotion,
  getTierMeterData,
  getProfileScoreMeterData,
  getActivityScoreMeterData,
  getTrafficScoreMeterData,
  getVolumeScoreMeterData,
  getReferralScoreMeterData
} from '../../../../Helpers/tier_helpers';
import { REFERRAL_PAYOUT_RATE, REFERRAL_LENGTH_MONTHS_DISPLAY } from '../../../../Helpers/referral_helpers';
import { getDaysSinceJoining, getValidReferralCount, getInvalidReferralCount } from '../../../../Helpers/user_helpers';

const UserTierPanel = props => {
  const { user } = props;
  const userTiers = getUserTiers();
  const userTier = getUserTier(user);
  const invalidReferralCount = getInvalidReferralCount(user);
  const validReferralCount = getValidReferralCount(user);
  const daysSinceJoining = getDaysSinceJoining(user);

  // Warnings
  const isAtRiskOfDemotion = isUserAtRiskOfTierDemotion(user);
  const daysUntilDemotion = getDaysUntilTierDemotion(user);

  // Meter representing their aggregate score
  const mainMeterData = getTierMeterData(user);
  const profileMeterData = getProfileScoreMeterData(user);
  const activityMeterData = getActivityScoreMeterData(user);
  const trafficMeterData = getTrafficScoreMeterData(user);
  const volumeMeterData = getVolumeScoreMeterData(user);
  const referralMeterData = getReferralScoreMeterData(user);

  const breakdownMeters = [
    {
      title: 'Complete Your Profile',
      score: profileMeterData.points,
      maxScore: profileMeterData.maxPoints,
      data: getProfileScoreMeterData(user),
      description: 'Brands want to learn as much as possible about who you are, the products you talk about and what you offer.',
      customBreakdown: () => {
        const profileValues = profileMeterData.values;
        const hasProfilePhoto = profileValues.find(v => v.label === 'Photo Added').complete;
        const hasBio = profileValues.find(v => v.label === 'Added Bio').complete;
        const hasQuickLink = profileValues.find(v => v.label === 'Quick Link').complete;
        const hasConnectedSocial = profileValues.find(v => v.label === 'Connected Social').complete;
        const hasRecentCollection = profileValues.find(v => v.label === 'Recent Collection').complete;

        return (
          <div className='user-tier-panel-meter-outer-container profile-meter'>
            <div className={cn('profile-meter-section', { complete: hasProfilePhoto })}>
              <FontAwesomeIcon icon={hasProfilePhoto ? faCheckCircle : faCircle} className='profile-meter-section-mark' />
              Added Profile Photo
            </div>
            <div className={cn('profile-meter-section', { complete: hasBio })}>
              <FontAwesomeIcon icon={hasBio ? faCheckCircle : faCircle} className='profile-meter-section-mark' />
              Added Bio
            </div>
            <div className={cn('profile-meter-section', { complete: hasQuickLink })}>
              <FontAwesomeIcon icon={hasQuickLink ? faCheckCircle : faCircle} className='profile-meter-section-mark' />
              Added A Quick Link
            </div>
            <div className={cn('profile-meter-section', { complete: hasConnectedSocial })}>
              <FontAwesomeIcon icon={hasConnectedSocial ? faCheckCircle : faCircle} className='profile-meter-section-mark' />
              Connected Your Social Media
            </div>
            <div className={cn('profile-meter-section', { complete: hasRecentCollection })}>
              <FontAwesomeIcon icon={hasRecentCollection ? faCheckCircle : faCircle} className='profile-meter-section-mark' />
              Recently Added A Collection
            </div>
          </div>
        );
      }
    },
    {
      title: 'Recent Activity',
      score: activityMeterData.points,
      maxScore: activityMeterData.maxPoints,
      data: activityMeterData,
      description: 'Brands want to make sure you are actively generating links and responding to gifting requests on the platform.'
    },
    {
      title: 'Monthly Traffic',
      score: trafficMeterData.points,
      maxScore: trafficMeterData.maxPoints,
      data: trafficMeterData,
      description:
        daysSinceJoining >= 90
          ? `Brands want to make sure you drive clicks to their products on a consistent basis. This is calculated over the past 3 months.`
          : `Brands want to make sure you drive clicks to their products on a consistent basis. This is calculated over the past 3 months. Since you joined ${daysSinceJoining} day${
              daysSinceJoining === 1 ? '' : 's'
            } ago, this is calculated as your daily average of clicks multiplied by 30.`
    },
    {
      title: 'Monthly Order Volume',
      score: volumeMeterData.points,
      maxScore: volumeMeterData.maxPoints,
      data: volumeMeterData,
      description:
        daysSinceJoining >= 90
          ? `Brands want to make sure your audience shops your recommendations. This is calculated over the past 3 months.`
          : `Brands want to make sure your audience shops your recommendations. This is calculated over the past 3 months. Since you joined ${daysSinceJoining} day${
              daysSinceJoining === 1 ? '' : 's'
            } ago, this is calculated as your daily average of order volume multiplied by 30.`
    },
    {
      title: 'Growing The Community',
      score: referralMeterData.points,
      maxScore: referralMeterData.maxPoints,
      data: referralMeterData,
      description: `As the ShopMy community grows, your opportunities will grow! This won't negatively impact your score but can act as bonus points to get you to the next tier - also, we'll send you an additional ${REFERRAL_PAYOUT_RATE}% of their earnings for the first ${REFERRAL_LENGTH_MONTHS_DISPLAY} months! ${
        invalidReferralCount
          ? ` Please note that in order for a referral to count, they must maintain a score of 6 or higher after the first 30 days - currently only ${validReferralCount} of your ${invalidReferralCount +
              validReferralCount} referrals fit this metric.`
          : ''
      }`
    }
  ];

  if (!userTier) return null;
  return (
    <div className='user-tier-panel-outer'>
      <div className='user-tier-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Your Tier Progress</div>
          <div className='settings-section-subtitle'>
            This tier impacts the functionality unlocked on the platform. For more explanation on the rankings, click "Learn More" or check out our
            guide 
            <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/creator-tiers'>
              here
            </a>
          </div>

          {isAtRiskOfDemotion && (
            <div className='warning-msg'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              You still have {daysUntilDemotion} day{daysUntilDemotion === 1 ? '' : 's'} to get your points back up above your tier minumum before we
              will update it.
            </div>
          )}
          <div className='user-tier-panel-meter-outer-container'>
            <UserTierPanelMeter user={user} data={mainMeterData} />
            <div className='sections'>
              {userTiers.map((ut, idx) => {
                const nextTier = userTiers[idx + 1];
                const isActive = ut.tier === userTier.tier;
                return (
                  <div
                    key={ut.label}
                    className={cn('section', { active: isActive })}
                    style={{ width: `${(nextTier?.minScore || 100) - ut.minScore}%` }}
                  >
                    <div className={cn('label', { 'desktop-only': !isActive })}>{ut.label}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='settings-section'>
          <div className='settings-section-title'>What You've Unlocked</div>
          <div className='unlocked-grid'>
            {userTiers.map(ut => {
              return (
                <div
                  key={ut.label}
                  className={cn('unlocked-grid-column', {
                    unlocked: userTier.tier < ut.tier,
                    current: userTier.tier === ut.tier,
                    locked: userTier.tier > ut.tier
                  })}
                >
                  <div className='header cell'>{ut.label}</div>
                  {ut?.unlocked.map(unlock => (
                    <div key={unlock} className='cell'>
                      <span className='bullet'>•</span>
                      {unlock}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>

        <div className='settings-section'>
          <div className='settings-section-title'>How We Calculated Your Tier</div>
          <div className='settings-section-subtitle'>
            We calculate your tier based on a 100 point scale of where you fall on the following traits. To read more on how tiering works, check out
            our tiering explainer guide{' '}
            <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/creator-tiers'>
              here
            </a>
            .
          </div>

          <div className='user-tier-panel-breakdown-container'>
            {breakdownMeters.map(meter => (
              <div className='user-tier-panel-meter-outer-container'>
                <UserTierPanelBreakdown key={meter.title} {...meter} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserTierPanelBreakdown = props => {
  const [explanationIsVisible, setExplanationIsVisible] = useState(false);
  const { user, title, score, maxScore, data, description, customBreakdown } = props;
  const cappedScore = Math.min(data.score, 100);

  return (
    <div className='user-tier-panel-breakdown-outer' style={{ marginBottom: explanationIsVisible ? '30px' : '0px' }}>
      <div className='user-tier-panel-breakdown-inner'>
        <div onClick={() => setExplanationIsVisible(!explanationIsVisible)} className='user-tier-panel-breakdown-basic-info'>
          <div>
            <div className='user-tier-panel-breakdown-basic-info-score'>
              {parseInt(score)}/{maxScore}
            </div>
            <div className='user-tier-panel-breakdown-basic-info-title'>{title}</div>
          </div>
          <div>
            <FontAwesomeIcon icon={explanationIsVisible ? faMinusLarge : faPlusLarge} />
          </div>
        </div>

        {explanationIsVisible && customBreakdown ? (
          customBreakdown()
        ) : explanationIsVisible ? (
          <>
            <div className='user-tier-panel-breakdown-basic-info-description'>{description}</div>
            <UserTierPanelMeter user={user} data={data} />
          </>
        ) : (
          <div className='user-tier-panel-meter-outer'>
            <div className='user-tier-panel-meter-inner tiny'>
              <div className='spine' />
              <div className={cn('spine active')} style={{ width: `calc(${cappedScore}% + 30px)` }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

/* See tier_helpers.js formatting descriptions. */
const UserTierPanelMeter = props => {
  const { user, data } = props;
  const { score, values, pointsInt, pointsLabel } = data;

  const cappedScore = Math.min(score, 100);
  return (
    <div className='user-tier-panel-meter-outer'>
      <div className='user-tier-panel-meter-inner'>
        <div className='spine' />
        <div className={cn('spine active')} style={{ width: `calc(${cappedScore}% + 30px)` }} />

        {values.map(val => {
          const to = val.getLinkToFromUserObject ? val.getLinkToFromUserObject(user) : null;
          return (
            <div
              key={val.value}
              className={cn('mark-container', { complete: cappedScore >= val.value })}
              style={{ left: `calc(${_.min([val.value, 100])}% - 10px)` }}
            >
              <div className='mark'>{val.valueLabel}</div>
              {to ? (
                <>
                  {val.label && (
                    <Link to={to} className='mark-label desktop-only'>
                      {val.label}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  )}
                  {val.shortLabel && (
                    <Link to={to} className='mark-label mobile-only'>
                      {val.shortLabel}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {val.label && <div className='mark-label desktop-only'>{val.label}</div>}
                  {val.shortLabel && <div className='mark-label mobile-only'>{val.shortLabel}</div>}
                </>
              )}
            </div>
          );
        })}

        <div className='mark-container' style={{ left: `calc(${cappedScore}% - 10px)` }}>
          <div className='mark current'>{pointsInt}</div>
          {pointsLabel && <div className='mark-label-pointer' />}
          {pointsLabel && <div className='mark-label current'>{pointsLabel}</div>}
        </div>
      </div>
    </div>
  );
};

UserTierPanel.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserTierPanel;
