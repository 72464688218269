import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faInfoCircle, faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck, faCheck, faTimes, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import ConfirmPrompt from '../../General/ConfirmPrompt';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import './OpportunityExpectations.scss';

import Loader from '../../Loader/Loader';
import Tooltip from '../../General/Tooltip';

import { updateOpportunityResult, updateOpportunityExpectation, syncActiveOpportunityFullData } from '../../../Actions/OpportunityActions';
import { updateOpportunityRequest } from '../../../Actions/AnalyticsActions';
import { getBrand, getSocialAccounts } from '../../../Helpers/user_helpers';
import { prepopulateIntercomMessage } from '../../../Helpers/chat_helpers';
import { opportunityConstants, getMinimumDateForLinkCreation, getOpportunityRequestAdjustedExpectations } from '../../../Helpers/opportunity_helpers';
import { getPrettyNumber, getPrettyTimeAgoFromNow } from '../../../Helpers/formatting';

const OpportunityExpectations = props => {
  const { user, request, opportunity } = props;
  const { result } = request;
  const { mentionsExpected, linksExpected, linkingDaysExpected, mentionDaysExpected } = getOpportunityRequestAdjustedExpectations(
    opportunity,
    request
  );

  const resultStats = result?.stats ? JSON.parse(result.stats) : {};
  const { expectations, invalid_expectations } = result || {};
  const areExpectationsLoading = !('expectations' in result);

  const minimumDateForLink = getMinimumDateForLinkCreation(result, opportunity);
  const isBrand = !!getBrand(user);
  const isUser = !isBrand;

  const brand = getBrand(user) || opportunity.brand || request.brand;
  const validSocialTags = (opportunity.socialTags || brand?.socialTags || '')
    .split(',')
    .filter(tag => tag)
    .map(tag => tag.toLowerCase());
  const validDomains = opportunity.acceptableDomains?.trim()?.length ? opportunity.acceptableDomains?.split(',') || [] : [brand.domain];

  // Posts & Links
  const allExpectations = _.concat(expectations, invalid_expectations || []);
  const allMentionExpectations = _.orderBy(allExpectations.filter(e => e.Mention_id), ['dayCountingFor', 'mention.contentPublishedAt'], ['desc', 'asc']); // prettier-ignore
  const allLinkExpectations = _.orderBy(allExpectations.filter(e => e.Pin_id), 'pin.createdAt', 'desc'); // prettier-ignore

  // Allow Displaying All Expectations
  const [showingAllMentions, setShowingAllMentions] = React.useState(false);
  const [showingAllLinks, setShowingAllLinks] = React.useState(false);
  const [showingAllMentionDays, setShowingAllMentionDays] = React.useState(false);
  const [showingAllLinkingDays, setShowingAllLinkingDays] = React.useState(false);

  const expectationSections = [
    {
      title: 'Social Mentions',
      disclaimer: validSocialTags
        ? `To show up in this section, the mention must have used one of the valid tracking tags: ${validSocialTags
            .map(t => '@' + t.replace(/[@#]/, ''))
            .join(', ')}. If the mention is not appearing after a few hours, please get in contact with our team via chat.`
        : `To show up in this section, the mention must have used one of the valid tracking tags. If the mention is not appearing, please get in contact with our team via chat.`,
      missingVariable: 'missingMentions',
      expectationVariable: 'mentionsExpected',
      expectationValueVariable: 'mentions',
      expectationValueVariableLabel: 'Mentions',
      missingCount: resultStats.missingMentions,
      completeCount: resultStats.mentions,
      expectedCount: mentionsExpected,
      expectations: allMentionExpectations,
      missingImageIcon: faLink,
      errorMsg: isUser && !getSocialAccounts(user).length && (
        <>
          You have not connected your socials! You must do this in order for tracking to work. Click{' '}
          <Link to='/settings?tab=Connected+Accounts'>here</Link> to go to account settings where you can connect your socials.
        </>
      ),
      classPrefix: 'mention',
      groupByDay: false,
      showAll: showingAllMentions,
      toggleShowAll: () => setShowingAllMentions(!showingAllMentions)
    },

    {
      title: 'Sharing Unique Links',
      disclaimer: `Links must be unique, to ${
        validDomains.length > 1 ? `one of the following domains: ${validDomains.join(', ')}` : `${validDomains[0]}`
      }, generated on or after ${minimumDateForLink.format('MMM Do, YYYY')}, and must have driven at least ${
        opportunityConstants.MINUMUM_LINK_CLICKS_TO_REGISTER_AS_EXPECTATION
      } clicks. Link stats are refreshed every 30 minutes.${
        isBrand ? '' : ` You can generate links however you want - Links Tab, Mobile App, Snapshop, etc.`
      }`,
      missingVariable: 'missingLinks',
      expectationVariable: 'linksExpected',
      expectationValueVariable: 'links',
      expectationValueVariableLabel: 'Links',
      missingCount: resultStats.missingLinks,
      completeCount: resultStats.links,
      expectedCount: linksExpected,
      expectations: allLinkExpectations,
      missingImageIcon: faLink,
      classPrefix: 'link',
      groupByDay: false,
      showAll: showingAllLinks,
      toggleShowAll: () => setShowingAllLinks(!showingAllLinks)
    },
    {
      title: 'Days With Social Mentions',
      disclaimer: validSocialTags
        ? `To show up in this section, the mention must have used one of the valid tracking tags: ${validSocialTags
            .map(t => '@' + t.replace(/[@#]/, ''))
            .join(', ')}. If the mention is not appearing, please get in contact with our team via chat.`
        : `To show up in this section, the mention must have used one of the valid tracking tags. If the mention is not appearing, please get in contact with our team via chat.`,
      missingVariable: 'missingMentionDays',
      expectationVariable: 'mentioningDaysExpected',
      expectationValueVariable: 'mentioningDays',
      expectationValueVariableLabel: 'Mention Days',
      missingCount: resultStats.missingMentionDays,
      completeCount: resultStats.mentionDays,
      expectedCount: mentionDaysExpected,
      expectations: allMentionExpectations,
      missingImageIcon: faLink,
      isDayExpectation: true,
      classPrefix: 'mention',
      errorMsg: isUser && !getSocialAccounts(user).length && (
        <>
          You have not connected your socials! You must do this in order for tracking to work. Click{' '}
          <Link to='/settings?tab=Connected+Accounts'>here</Link> to go to account settings where you can connect your socials.
        </>
      ),
      groupByDay: true,
      showAll: showingAllMentionDays,
      toggleShowAll: () => setShowingAllMentionDays(!showingAllMentionDays)
    },
    {
      title: 'Days Sharing Unique Links',
      disclaimer: `Links must be unique, to ${
        validDomains.length > 1 ? `one of the following domains: ${validDomains.join(', ')}` : `${validDomains[0]}`
      }, generated on or after ${minimumDateForLink.format('MMM Do, YYYY')}, and must have driven at least ${
        opportunityConstants.MINUMUM_LINK_CLICKS_TO_REGISTER_AS_EXPECTATION_IN_SINGLE_DAY
      } clicks in a single day. The same link only counts for a single day.`,
      missingVariable: 'missingLinkingDays',
      expectationVariable: 'linkingDaysExpected',
      expectationValueVariable: 'linkingDays',
      expectationValueVariableLabel: 'Linking Days',
      missingCount: resultStats.missingLinkingDays,
      completeCount: resultStats.linkingDays,
      expectedCount: linkingDaysExpected,
      expectations: allLinkExpectations,
      missingImageIcon: faLink,
      isDayExpectation: true,
      classPrefix: 'link',
      groupByDay: true,
      showAll: showingAllLinkingDays,
      toggleShowAll: () => setShowingAllLinkingDays(!showingAllLinkingDays)
    }
  ]
    .filter(section => {
      if (section.expectedCount) return true; // Show all sections with expectations
      if (section.isDayExpectation) return false; // Don't show day expectations if there are no expectations
      return section.completeCount || section.missingCount;
    })
    .sort((a, b) => b.expectedCount - a.expectedCount);

  // If no expectations and none expected, return nothing
  if (!expectationSections.length) return null;

  return (
    <div className='opportunity-expectations-panel'>
      <div className='expectations-sections'>
        {expectationSections.map((section, idx) => {
          const {
            title,
            disclaimer,
            showAll,
            toggleShowAll,
            missingVariable,
            missingCount,
            completeCount,
            expectedCount,
            expectations,
            missingImageIcon,
            expectationVariable,
            expectationValueVariable,
            expectationValueVariableLabel,
            groupByDay,
            errorMsg,
            classPrefix
          } = section;
          const completedExpectation = completeCount + missingCount >= expectedCount;

          let expectationsToShow;
          if (showAll) expectationsToShow = expectations.length;
          else if (completedExpectation && expectedCount)
            expectationsToShow =
              expectations.length <= 5
                ? completeCount + missingCount
                : Math.min(Math.max(expectations.length, expectedCount), Math.max(5, expectedCount));
          else expectationsToShow = expectedCount || Math.min(expectations.length, 5);

          const canShowMore = expectations.length > expectationsToShow;
          const canShowLess = !!showAll;
          const isMentionSection = classPrefix === 'mention';

          const adjustExpectations = () => {
            if (result.isCompleted) {
              return window.ALERT.error('This opportunity has already been marked as completed. You cannot adjust the expectations.');
            }

            confirmAlert({
              customUI: ({ onClose }) => (
                <ConfirmPrompt
                  header='Adjust Expectations'
                  subheader={`You can adjust the number of expected ${expectationValueVariableLabel} for this opportunity. `}
                  onCancel={onClose}
                  customInputFields={[
                    {
                      placeholder: opportunity[expectationValueVariable] || '0',
                      preloaded: expectedCount,
                      value: expectationValueVariable,
                      isSingleLine: true
                    }
                  ]}
                  submitMustReturnTrueToClose
                  onSubmitAwait={async responseValues => {
                    const previousAdjustments = JSON.parse(request.expectationAdjustments || '{}');

                    // Ensure validity
                    const responseAsValue = +responseValues[expectationValueVariable];
                    if (isNaN(responseAsValue) || responseAsValue < 0) {
                      window.ALERT.error('Please enter a valid number.');
                      return false;
                    }

                    const newAdjustments = _.omitBy(
                      {
                        ...previousAdjustments,
                        [expectationValueVariable]: responseAsValue - opportunity[expectationVariable]
                      },
                      v => !v
                    );
                    if (_.isEqual(newAdjustments, previousAdjustments)) return true;

                    const resp = await props.updateOpportunityRequest(request, {
                      expectationAdjustments: JSON.stringify(newAdjustments)
                    });
                    if (resp.error) {
                      window.ALERT.error('There was an error adjusting the expectations. Please try again.');
                      return false;
                    } else {
                      window.ALERT.success('The expectations have been adjusted. The opportunity will update shortly.', {
                        duration: 4000
                      });
                      return true;
                    }
                  }}
                />
              )
            });
          };

          const uploadOwnData = () => {
            if (isUser) {
              confirmAlert({
                customUI: ({ onClose }) => (
                  <ConfirmPrompt
                    header={'Not seeing your content?'}
                    subheader={
                      isMentionSection
                        ? `We check for mentions every two hours. If the mention is not appearing after a few hours, please fill out the following form.`
                        : `We check for links every 30 minutes. If the link is not appearing after a few hours, please fill out the following form.`
                    }
                    onCancel={onClose}
                    secondaryBtn={
                      window.__ADMIN_CONTROL_MODE__
                        ? {
                            display: 'Give Credit For Missing',
                            onClick: () => {
                              props.updateOpportunityResult(result, {
                                stats: JSON.stringify({
                                  ...resultStats,
                                  [missingVariable]: resultStats[missingVariable] ? resultStats[missingVariable] + 1 : 1
                                })
                              });
                              onClose();
                            }
                          }
                        : null
                    }
                    customInputFields={[
                      ...(isMentionSection
                        ? [
                            {
                              display: 'Content URL (optional)',
                              placeholder: 'https://www.instagram.com/p/CI3Z4QzATPI/',
                              value: 'url',
                              isSingleLine: true
                            }
                          ]
                        : []),
                      {
                        display: 'Explanation',
                        placeholder: isMentionSection
                          ? 'I shared the link on my Instagram story and it is not showing up.'
                          : `I created a link and it is not showing up even after getting ${opportunityConstants.MINUMUM_LINK_CLICKS_TO_REGISTER_AS_EXPECTATION} clicks.`,
                        value: 'description',
                        isSingleLine: false
                      }
                    ]}
                    onSubmit={responseValues => {
                      const { description, url } = responseValues;
                      prepopulateIntercomMessage(
                        `My content isn't appearing for an opportunity with ${request.brand.name} called "${opportunity.title}". ${description}${
                          url ? ` The content URL is ${responseValues.url}` : ''
                        }`
                      );
                    }}
                  />
                )
              });
            } else {
              confirmAlert({
                title: 'Missing Content',
                message: `Please click below to give credit to ${request.user.name} for this expectation. This will mark the expectation as fulfilled, however it will not count any associated statistics.`,
                buttons: [
                  { label: 'No', className: 'cancel', onClick: () => {} },
                  {
                    label: 'Yes',
                    onClick: () => {
                      props.updateOpportunityResult(result, {
                        stats: JSON.stringify({
                          ...resultStats,
                          [missingVariable]: resultStats[missingVariable] ? resultStats[missingVariable] + 1 : 1
                        })
                      });
                      window.ALERT.success(
                        `The expectation has been marked as fulfilled, if all expectations are met the opportunity will be marked as completed within 15 minutes.`
                      );
                    }
                  }
                ]
              });
            }
          };

          return (
            <div key={title} className='expectations-section'>
              <div className='section-title-container'>
                <div className='section-title-main'>
                  {expectedCount ? (
                    <div className={cn('progress-badge', { completed: completedExpectation })}>
                      {completedExpectation && <FontAwesomeIcon icon={faBadgeCheck} />}
                      {_.min([completeCount + missingCount, expectedCount])} of {expectedCount}
                    </div>
                  ) : (
                    <div className='progress-badge'>{completeCount + missingCount}</div>
                  )}

                  <div className='title-container'>
                    <div className='title'>
                      {title}
                      {disclaimer && isBrand && (
                        <Tooltip message={disclaimer}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      )}
                    </div>
                    {!completedExpectation && isUser && <div className='disclaimer'>{disclaimer}</div>}
                  </div>
                </div>
                <div className='section-title-actions'>
                  {isBrand ? (
                    <>
                      {!result.isCompleted && !!expectedCount && (
                        <div onClick={adjustExpectations} className='action btn'>
                          Adjust Expectations
                        </div>
                      )}
                      {!result.isCompleted && !!expectedCount && (
                        <div onClick={uploadOwnData} className='action btn'>
                          Give Credit
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {!result.isCompleted && !!expectedCount && (
                        <div onClick={uploadOwnData} className='action text'>
                          Missing Content?
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {errorMsg && (
                <div className='section-error-container'>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <div className='error'>{errorMsg}</div>
                </div>
              )}
              <div className='expectations'>
                {_.range(expectationsToShow).map((i, idx) => {
                  const expectation = expectations[idx];
                  const isInvalid = expectation && expectation.hasBeenMarkedInvalid;
                  const isMissing = !expectation && idx < completeCount + missingCount;
                  const stats = JSON.parse(expectation?.stats || '{}');
                  const { mention, pin, dayCountingFor } = expectation || {};

                  const isMention = !!mention;
                  const isEmptyMention = !isMention && classPrefix === 'mention';
                  const isPin = !!pin;
                  const isComplete = isMention || isPin || isMissing;
                  const image = isMention ? mention.image : isPin ? pin.image : null;
                  const log = () => console.info({ expectation, opportunity, request });
                  const additionalClasses = {
                    [classPrefix]: true,
                    invalid: isInvalid,
                    loading: areExpectationsLoading,
                    'missing-image': isComplete && !image,
                    'missing-but-counts': isMissing
                  };
                  const timeStamp = isMention ? mention.contentPublishedAt : isPin ? pin.createdAt : null;

                  //  Check if URL is Expired ( Instagram Stories )
                  let mediaUrl;
                  if (mention) {
                    const isUrlExpired = mention.url?.includes('stories') && moment().diff(mention.contentPublishedAt, 'minutes') > 24 * 60;
                    mediaUrl = isUrlExpired ? mention.video || mention.image : mention.url;
                  }

                  let metadata = [];
                  let metadataDisclaimer;
                  if (groupByDay && expectation) {
                    const othersOnSameDay = expectations.filter(e => e.dayCountingFor === dayCountingFor);
                    const indexOfSameDay = othersOnSameDay.map(i => i.id).indexOf(expectation.id);
                    const hasOthersOnSameDay = othersOnSameDay.length > 1;
                    const isFirstToCount = indexOfSameDay === 0;
                    metadata.push(
                      dayCountingFor
                        ? isFirstToCount && hasOthersOnSameDay
                          ? `${moment(dayCountingFor).format('MMM Do')}`
                          : hasOthersOnSameDay
                          ? `${isMention ? `Mention` : `Link`} #${indexOfSameDay + 1} on ${moment(dayCountingFor).format('MMM Do')}`
                          : `${moment(dayCountingFor).format('MMM Do')}`
                        : 'Insufficient Clicks To Count Day'
                    );
                  } else if (timeStamp) {
                    const daysAgo = moment().diff(timeStamp, 'days');
                    daysAgo > 30
                      ? metadata.push(moment(timeStamp).format('MMM Do'))
                      : metadata.push(getPrettyTimeAgoFromNow(timeStamp, { longForm: true }));
                  }
                  stats.views && metadata.push(`${stats.views} view${stats.views === 1 ? '' : 's'}`);
                  stats.clicks && metadata.push(`${stats.clicks} click${stats.clicks === 1 ? '' : 's'}`);
                  stats.orders && metadata.push(`${stats.orders} order${stats.orders === 1 ? '' : 's'}`);
                  stats.volume && metadata.push(`$${getPrettyNumber(stats.volume)} in volume`);
                  stats.likes && metadata.push(`${stats.likes} like${stats.likes === 1 ? '' : 's'}`);
                  stats.shares && metadata.push(`${stats.shares} share${stats.shares === 1 ? '' : 's'}`);
                  stats.comments && metadata.push(`${stats.comments} comment${stats.comments === 1 ? '' : 's'}`);
                  if (expectation?.isExtensionOfPreviousStory) {
                    metadata.push('Story Continuation');
                    metadataDisclaimer =
                      'This is a continuation of a previous story. We only count this as a single mention and take the views from the most viewed story.';
                  }

                  const revalidateExpectation = e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (expectation) {
                      confirmAlert({
                        title: 'Just confirming',
                        message: `Are you sure you want to revalidate this ${
                          isMention ? 'mention' : 'link'
                        }? If so, we will mark it as valid and the aggregate stats will update shortly.`,
                        buttons: [
                          { label: 'No', className: 'cancel', onClick: () => {} },
                          {
                            label: 'Yes',
                            onClick: () => {
                              // Mark this as valid
                              window.__ADD_EVENT__(`Marked ${isMention ? 'Mention' : 'Link'} as Valid`, {
                                Opportunity_id: opportunity.id,
                                Opportunity_title: opportunity.title,
                                Expectation_id: expectation.id
                              });
                              props
                                .updateOpportunityExpectation(expectation, {
                                  hasBeenMarkedInvalid: false
                                })
                                .then(resp => props.syncActiveOpportunityFullData(opportunity.id));
                              window.ALERT.success(
                                `The ${isMention ? 'mention' : 'link'} has been revalidated. The aggregate stats will update shortly.`
                              );
                            }
                          }
                        ]
                      });
                    }
                  };

                  const removeExpectation = e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (expectation) {
                      confirmAlert({
                        title: 'Just confirming',
                        message: `Are you sure you want to remove this ${
                          isMention ? 'mention' : 'link'
                        }? If so, we will remove it and the aggregate stats will update shortly. You cannot undo this action.`,
                        buttons: [
                          { label: 'No', className: 'cancel', onClick: () => {} },
                          {
                            label: 'Yes',
                            onClick: () => {
                              // Mark this as invalid
                              window.__ADD_EVENT__(`Marked ${isMention ? 'Mention' : 'Link'} as Invalid`, {
                                Opportunity_id: opportunity.id,
                                Opportunity_title: opportunity.title,
                                Expectation_id: expectation.id
                              });
                              props
                                .updateOpportunityExpectation(expectation, {
                                  hasBeenMarkedInvalid: true
                                })
                                .then(resp => props.syncActiveOpportunityFullData(opportunity.id));
                              window.ALERT.success(
                                `The ${isMention ? 'mention' : 'link'} has been removed. The aggregate stats will update shortly.`
                              );
                            }
                          }
                        ]
                      });
                    } else if (isMissing) {
                      confirmAlert({
                        title: 'Just confirming',
                        message: `Are you sure you want to remove this credit for missing ${
                          isMention ? 'mention' : 'link'
                        }? If so, we will remove it as a fulfilled expectation.`,
                        buttons: [
                          { label: 'No', className: 'cancel', onClick: () => {} },
                          {
                            label: 'Yes',
                            onClick: () => {
                              window.__ADD_EVENT__(`Removed Credit for Missing ${isMention ? 'Mention' : 'Link'}`, {
                                Opportunity_id: opportunity.id,
                                Opportunity_title: opportunity.title,
                                Expectation_id: expectation.id
                              });
                              props
                                .updateOpportunityResult(result, {
                                  stats: JSON.stringify({
                                    ...resultStats,
                                    [missingVariable]: resultStats[missingVariable] - 1
                                  })
                                })
                                .then(resp => props.syncActiveOpportunityFullData(opportunity.id));
                            }
                          }
                        ]
                      });
                    }
                  };

                  const wrapInLinkIfNeeded = els => {
                    return mediaUrl ? (
                      <a
                        key={mention?.id || pin?.id || idx}
                        href={mediaUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={cn('expectation', additionalClasses)}
                      >
                        {els}
                      </a>
                    ) : (
                      <div key={mention?.id || pin?.id || idx} className={cn('expectation', additionalClasses)}>
                        {els}
                      </div>
                    );
                  };

                  return wrapInLinkIfNeeded(
                    <>
                      {(isMissing || expectation || isInvalid) && (
                        <div onClick={isInvalid ? revalidateExpectation : removeExpectation} className='remove-expectation'>
                          <FontAwesomeIcon icon={isInvalid ? faRotateRight : faTimes} />
                        </div>
                      )}
                      {!!isInvalid && (
                        <div className='invalid-expectation-container'>
                          <div className='invalid-expectation'>Marked Invalid</div>
                        </div>
                      )}
                      <div onClick={log} key={idx} className={cn('media-container', additionalClasses)}>
                        {areExpectationsLoading ? (
                          <Loader size={64} />
                        ) : isComplete ? (
                          image ? (
                            <div className='image-container'>
                              <img src={image} alt={idx} />
                            </div>
                          ) : (
                            <div className='image-container empty'>
                              <FontAwesomeIcon icon={isMissing ? faCheck : missingImageIcon} />
                            </div>
                          )
                        ) : (
                          <div className='image-container incomplete'>#{idx + 1}</div>
                        )}
                      </div>
                      <div className='data-container'>
                        {isComplete ? (
                          <div className='title'>
                            {isMention ? mention.title : isPin ? pin.title : isMissing ? `Credit for missing result` : 'Loading...'}
                          </div>
                        ) : (
                          <div className='title empty'>Waiting on {isEmptyMention ? 'Social Mention' : 'Link'}</div>
                        )}
                        {metadata.length > 0 &&
                          (metadataDisclaimer ? (
                            <Tooltip message={metadataDisclaimer}>
                              <div className='metadata'>{metadata.join(' • ')}</div>
                            </Tooltip>
                          ) : (
                            <div className='metadata'>{metadata.join(' • ')}</div>
                          ))}
                      </div>
                    </>
                  );
                })}
              </div>
              {(canShowMore || canShowLess) && (
                <div className='toggle-show-all' onClick={toggleShowAll}>
                  {`Show ${showAll ? 'Less' : `${expectations.length - expectationsToShow} More`}`}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

OpportunityExpectations.propTypes = {
  // From Inside
  user: PropTypes.object.isRequired,
  updateOpportunityResult: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  updateOpportunityExpectation: PropTypes.func.isRequired,
  syncActiveOpportunityFullData: PropTypes.func.isRequired,

  // From Outside
  opportunity: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateOpportunityResult,
  updateOpportunityRequest,
  updateOpportunityExpectation,
  syncActiveOpportunityFullData
})(OpportunityExpectations);
