import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ConnectPaypal.scss';
import { useLocation, Link } from 'react-router-dom';

import { linkPaypalAccount } from '../../Actions/PayoutActions';
import { getUsername } from '../../Helpers/user_helpers';

import paypalIcon from '../../static/images/misc/paypal.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

const ConnectPaypal = props => {
  const { user } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const code = queryParams.get('code');
  const error = queryParams.get('error');
  const error_description = queryParams.get('error_description');
  const success = !!code;
  const inviteLink = `/invite/${getUsername(user)}`;

  useEffect(() => {
    if (!code) return;
    else if (!user) return window.ALERT.error('You must be logged in to connect your PayPal account.');
    props.linkPaypalAccount(user, code);
  }, [code]);

  return (
    <div className='connect-paypal-outer'>
      <div className='connect-paypal-inner'>
        <div className='connect-paypal-header'>
          <div className='connect-paypal-header-icon-container'>
            <img className='connect-paypal-header-icon' src={paypalIcon} alt='paypal icon' />
            <div className={cn('connect-paypal-header-success-icon-container', { success })}>
              <FontAwesomeIcon icon={success ? faCheck : faTimes} className='connect-paypal-header-success-icon' />
            </div>
          </div>
          <div className='connect-paypal-header-title'>{success ? 'Successfully Connected' : 'Connection Unsuccessful'}</div>
          <div className='connect-paypal-header-subtitle'>
            {error ? (
              <div>
                <div className='connect-paypal-header-subtitle'>Sorry, we weren't able to connect your PayPal account.</div>
                <div className='connect-paypal-header-subtitle'>
                  {error} - {error_description}
                </div>
              </div>
            ) : success ? (
              <div className='connect-paypal-header-subtitle'>Contratulations, you've connected to PayPal!</div>
            ) : (
              <div className='connect-paypal-header-subtitle'>
                Please try or retry connecting your PayPal through your account settings. We were unable to connect your account at this time.
              </div>
            )}
          </div>
        </div>

        <div className='connect-paypal-recommendations'>
          <div className='connect-paypal-recommendation'>
            <div className='connect-paypal-recommendation-title'>Invite Creators to ShopMy</div>
            <div className='connect-paypal-recommendation-description'>
              For everyone you invite to join ShopMy, you both receive a 10% bonus on all of their commissions and collaborations for the first 6
              months after they join!
            </div>
            <Link to={inviteLink} className='connect-paypal-recommendation-link'>
              Refer a Friend
            </Link>
          </div>

          <div className='connect-paypal-recommendation'>
            <div className='connect-paypal-recommendation-title'>Connect Your Socials</div>
            <div className='connect-paypal-recommendation-description'>
              Your social media handles will appear on your public shop header. Linking your accounts will allow you to share your analytics for brand
              collaborations—we highly recommend it. We do not share your data without your consent.
            </div>
            <Link to='/settings?tab=Connected+Accounts' className='connect-paypal-recommendation-link'>
              Go to Account Settings
            </Link>
          </div>

          <div className='connect-paypal-recommendation'>
            <div className='connect-paypal-recommendation-title'>Link Your Favorite Products</div>
            <div className='connect-paypal-recommendation-description'>
              Creating quick links to your favorite products will allow you to easily share them with your followers.
            </div>
            <Link to='/links' className='connect-paypal-recommendation-link'>
              View Your Links Tab
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ConnectPaypal.propTypes = {
  user: PropTypes.object.isRequired,
  linkPaypalAccount: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  linkPaypalAccount
})(ConnectPaypal);
