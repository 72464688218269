import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { SortableElement } from 'react-sortable-hoc';
import { SortableContainer } from '../../Helpers/sort_helpers';
import arrayMove from 'array-move';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import './CollectionProducts.scss';

import { editPin as editPinAPI } from '../../APIClient/pins';
import { saveCollection as saveCollectionAPI } from '../../APIClient/collections';
import { addEvent } from '../../APIClient/events';
import { getGMTTime } from '../../Helpers/formatting';
import { isQuickPage, getCollectionsTitle } from '../../Helpers/helpers';
import { getUserId, canEditCollection, isYourCollection } from '../../Helpers/user_helpers';

import CollectionProduct from './CollectionProduct';

class CollectionProducts extends Component {
  static propTypes = {
    syncCollection: PropTypes.func.isRequired,
    toggleEditMode: PropTypes.func.isRequired,
    toggleAddingMode: PropTypes.func.isRequired,
    setVisibleProduct: PropTypes.func.isRequired,
    setAnalyticsMode: PropTypes.func.isRequired,
    togglePinToMove: PropTypes.func.isRequired,
    collection: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
    fetchingAnalytics: PropTypes.bool.isRequired,
    adminControlMode: PropTypes.bool.isRequired,
    lastLocation: PropTypes.object
  };

  componentDidMount() {
    const { user, collection } = this.props;
    const { id, User_id } = collection;
    const canEdit = canEditCollection(collection, user);
    const collectionUser = collection.user;

    if (!canEdit) {
      addEvent('COLLECTION_VIEW', {
        eventUserId: getUserId(user),
        collectionId: id,
        userId: User_id,
        collectionName: collection.name,
        shopUsername: _.get(collectionUser, 'username'),
        shopName: _.get(collectionUser, 'name')
      });
    }

    // Set scroll listener and propagate it to all children
    this.scrollPanel &&
      this.scrollPanel.addEventListener('scroll', e => {
        _.values(this.scrollDispatch).forEach(fn => fn(e));
      });
  }

  componentDidUpdate(prevProps) {
    const currentCollectionSkinType = _.get(this.props, 'collection.skinType');
    const previousCollectionSkinType = _.get(prevProps, 'collection.skinType');
    if (currentCollectionSkinType !== previousCollectionSkinType) {
      this.setState({ hasChangedSkinTypeInThisSession: true });
    }
  }

  state = {
    tempPins: null, // used to ensure stablility while reordering
    viewingActionsForPin: null, // used due to glitchiness with ordering library
    pinBeingDeleted: null, // used due to glitchiness with ordering library
    pinBeingDuplicated: null, // used due to glitchiness with ordering library
    hasChangedSkinTypeInThisSession: false,
    movesToTop: 0 // used to track how many times the user has moved a pin to the top to add an affordance to change the default
  };

  addScrollSubscriber = (id, fn) => {
    this.scrollDispatch = this.scrollDispatch || {};
    this.scrollDispatch[id] = fn;
  };

  getPinIndex = pin => _.indexOf(_.map(this.state.tempPins || this.props.collection.pins, 'id'), pin.id);
  moveToTop = pin => {
    const { collection } = this.props;

    this.onSortEnd({ oldIndex: this.getPinIndex(pin), newIndex: 0 });

    // If the creator has moved pins to the top a few times, we want to flag to them that they can change the default for this collection
    this.setState({ movesToTop: this.state.movesToTop + 1 });
    if (!collection.addPinsToTop && this.state.movesToTop + 1 === 3) {
      setTimeout(() => {
        confirmAlert({
          title: 'Want products to default to the top?',
          message: `You've moved a few products to the top of this collection. Would you like to have new products default to the top of this collection? You can adjust this in the collection settings.`,
          buttons: [
            {
              label: 'Cancel',
              className: 'cancel',
              onClick: () => null
            },
            {
              label: 'Change Default to Top',
              onClick: async () => {
                await saveCollectionAPI(collection.id, { addPinsToTop: true });
                window.ALERT.success('New products will now be added to the top of this collection.');
                await this.props.syncCollection();
              }
            }
          ]
        });
      }, 400);
    }
  };

  onSortEnd = async ({ newIndex, oldIndex }) => {
    const { isRearranging } = this.state;
    if (isRearranging) return;
    const movingBack = newIndex > oldIndex;
    const pinToUpdateId = _.get(this.props, ['collection', 'pins', oldIndex, 'id']);
    const stampBeforeRank = _.get(this.props, ['collection', 'pins', movingBack ? newIndex : newIndex - 1, 'sortOrderRank'], -1 * getGMTTime());
    const stampAfterRank = _.get(this.props, ['collection', 'pins', movingBack ? newIndex + 1 : newIndex, 'sortOrderRank'], getGMTTime());
    let newStampRank = (stampAfterRank + stampBeforeRank) / 2;
    this.setState({
      isRearranging: true,
      tempPins: arrayMove(this.props.collection.pins, oldIndex, newIndex)
    });
    await editPinAPI(pinToUpdateId, { sortOrderRank: newStampRank });
    await this.props.syncCollection(); // Until we add this to the redux store
    this.setState({ isRearranging: false, tempPins: null });
  };

  updatePin = async (pin, updates) => {
    this.setState({
      tempPins: this.props.collection?.pins?.map(p => (p.id === pin.id ? { ...pin, ...updates } : p))
    });
    await editPinAPI(pin.id, updates);
    await this.props.syncCollection();
    this.setState({ tempPins: null });
  };

  getWarningMessage = () => {
    const { collection } = this.props;
    const { hasChangedSkinTypeInThisSession } = this.state;
    if (hasChangedSkinTypeInThisSession) {
      if (collection.skinType === 'compact' && !isMobile) {
        return `The compact style is only visible on a mobile device.`;
      } else if (collection.skinType === 'horizontal' && !isMobile) {
        return `The horizontal style is only visible on a mobile device.`;
      }
    }
    return null;
  };

  getFooterMessage = () => {
    const { collection, user } = this.props;
    const isYours = isYourCollection(collection, user);
    return (
      isYours &&
      isQuickPage() && (
        <div>
          You can edit this quick {getCollectionsTitle()} <Link to={`/collections/${collection.id}`}>here</Link>
        </div>
      )
    );
  };

  isShowingActionsPanel = pin => pin.id === this.state.showingActionsForPin?.id;
  setShowingActionsPanel = (pin, turnOn) => this.setState({ showingActionsForPin: turnOn ? pin : null });

  render() {
    const { collection, user, store, ui, analytics } = this.props;
    const { tempPins } = this.state;
    const canEdit = canEditCollection(collection, user);
    const SortableItem = SortableElement(({ value }) => {
      const pin = value;
      const index = this.getPinIndex(pin);

      // Need all this because the sorting library is jumpy and rerenders frequently
      const showingActionsPanel = this.isShowingActionsPanel(pin);
      const setShowingActionsPanel = newMode => this.setShowingActionsPanel(pin, newMode);
      const isBeingDeleted = pin.id === this.state.pinBeingDeleted?.id;
      const setPinBeingDeleted = () => this.setState({ pinBeingDeleted: pin });
      const clearBeingDeleted = () => this.setState({ pinBeingDeleted: null });
      const isBeingDuplicated = pin.id === this.state.pinBeingDuplicated?.id;
      const setPinBeingDuplicated = () => this.setState({ pinBeingDuplicated: pin });
      const clearBeingDuplicated = () => this.setState({ pinBeingDuplicated: null });
      const moveToTop = () => this.moveToTop(pin, index);
      return (
        <CollectionProduct
          index={index}
          user={user}
          collection={collection}
          ui={ui}
          store={store}
          analytics={analytics}
          showingActionsPanel={showingActionsPanel}
          isBeingDeleted={isBeingDeleted}
          isBeingDuplicated={isBeingDuplicated}
          setBeingDeleted={setPinBeingDeleted}
          setBeingDuplicated={setPinBeingDuplicated}
          clearBeingDeleted={clearBeingDeleted}
          clearBeingDuplicated={clearBeingDuplicated}
          moveToTop={moveToTop}
          setShowingActionsPanel={setShowingActionsPanel}
          togglePinToMove={this.props.togglePinToMove}
          fetchingAnalytics={this.props.fetchingAnalytics}
          syncCollection={this.props.syncCollection}
          setVisibleProduct={this.props.setVisibleProduct}
          adminControlMode={this.props.adminControlMode}
          setAnalyticsMode={this.props.setAnalyticsMode}
          toggleEditMode={this.props.toggleEditMode}
          updatePin={this.updatePin}
          lastLocation={this.props.lastLocation}
          addScrollSubscriber={this.addScrollSubscriber}
          pin={pin}
          key={pin.id}
        />
      );
    });
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className='products-list-wrapper'>
          {items.map((pin, idx) => (
            <SortableItem axis='xy' key={`item-${pin.id}`} index={idx} value={pin} />
          ))}
          <div className='products-list-add-new-btn-container' onClick={this.props.toggleAddingMode}>
            <div className='products-list-add-new-btn-icn'>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className='products-list-add-new-btn'>ADD {items.length ? 'NEW' : 'PRODUCT'}</div>
          </div>
        </div>
      );
    });

    const warningMessage = this.getWarningMessage();
    const footerMessage = this.getFooterMessage();
    return (
      <>
        {warningMessage && canEdit && (
          <div className='products-warning-message'>
            <FontAwesomeIcon icon={faInfoCircle} />
            {warningMessage}
          </div>
        )}
        <div
          ref={ref => (this.scrollPanel = ref)}
          className={cn('collection-products-outer-container', collection.skinType, { quick: isQuickPage() })}
        >
          {canEdit ? (
            <SortableList axis='xy' items={tempPins || collection.pins} onSortEnd={this.onSortEnd} useDragHandle />
          ) : (
            _.map(collection.pins, (pin, index) => (
              <CollectionProduct
                collection={collection}
                user={user}
                store={store}
                ui={ui}
                index={index}
                analytics={analytics}
                syncCollection={this.props.syncCollection}
                setVisibleProduct={this.props.setVisibleProduct}
                adminControlMode={this.props.adminControlMode}
                editPin={this.props.editPin}
                toggleVisibility={this.props.toggleVisibility}
                lastLocation={this.props.lastLocation}
                addScrollSubscriber={this.addScrollSubscriber}
                pin={pin}
                key={pin.id}
              />
            ))
          )}
        </div>
        {footerMessage && (
          <div className='products-footer-message'>
            <FontAwesomeIcon icon={faInfoCircle} />
            {footerMessage}
          </div>
        )}
      </>
    );
  }
}

export default CollectionProducts;
